import React, { Component } from 'react'
import {
  Container,
  Segment,
  Grid,
  Header,
  List,
} from 'semantic-ui-react'

export default class Footer extends Component {

    render() {  
      return (
        <Segment vertical style={{ padding: '5em 0em' }}>
        <Container>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as='h4' content='Contact Info' />
                <List link>
                  <List.Item as='a' href="tel:2076515517">(207) 651-5517</List.Item>
                  <List.Item as='a' href="mailto:NormandPropertyServices@gmail.com">NormandPropertyServices@gmail.com</List.Item>
                  <List.Item></List.Item>
                  <List.Item>Princeton, MN</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h4'>
                  About Us
                </Header>
                <p>
                  Locally owned and operated property service company located in Princeton, MN. We are dedicated to providing the highest quality services in the area.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      )
    }
  }
