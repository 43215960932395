import React from 'react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router } from 'react-router-dom'

import Footer from './content/Footer'
import {
  Container,
  Divider,
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react'
import headlineImage from './headline.jpg'

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
        <div style={{
              width: '100%',
              height: 500,
              display: 'inline-block',
              backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,2555,255,0.5)), url(${headlineImage})`,
              backgroundSize: 'cover',
            }}>
         <Container text>
    <Header
      as='h1'
      content='Normand'
      style={{
        fontSize: '4.5em',
        marginBottom: 0,
        marginTop: '1.8em',
      }}
    />
    <Header
      as='h1'
      content='Property Services'
      style={{
        fontSize: '4em',
        marginBottom: 0,
        marginTop: 0,
      }}
    />
    <Header
      as='h2'
      content='Professional service with a neighborly feel.'
      style={{
        fontSize: '1.7em',
        marginTop: '1.5em',
      }}
    />
  </Container>
    </div>
    <Segment style={{ padding: '4em 0em' }} vertical inverted>
      <Container>
        <Grid columns={2} textAlign='center' stackable>
          <Grid.Row>
            <Grid.Column width={5}>
                <Header as='h3' content='Holiday Lighting' inverted />
                <p>
                  Light up the neighborhood with a custom holiday light
                  installation this year! 
                </p>
            </Grid.Column>
            <Grid.Column width={5}>
                <Header as='h3' content='Lawn Care & Maintenance' inverted />
                <p>
                  Spend more time enjoying your weekends and let me 
                  care for all of your lawn and lanscaping needs!
                </p>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header as='h3' content='Lawn Aeration & Seeding' inverted />
                <p>
                  Provide your lawn with much-needed aeration for a more resilient
                  healthy lawn all year long.
                </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden section />
        <Grid columns={3} textAlign='center' stackable>
          <Grid.Row>
            <Grid.Column width={5}>
                <Header as='h3' content='Fertilizing & Weed Control' inverted />
                <p>
                  Tired of looking at dull grass and weeds scattered about? Aren't 
                  we all! Our fertilizing and weed control program can get your 
                  yard up to snuff!
                </p>
            </Grid.Column>
            <Grid.Column width={5}>
                <Header as='h3' content='Snow Removal Service' inverted />
                <p>
                  We provide full-service snow removal including shoveling,
                  salting, and clearing out around mailboxes or fire hydrants.
                </p>
            </Grid.Column>
            <Grid.Column width={5}>
                <Header as='h3' content='Landscaping and more!' inverted />
                <p>
                  Do you have a job, big or small, that you need done by a professional?
                  We're full licensed and insured so don't hesitate to call today!
                </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
          <Footer />
        </Router>
      </>
    );
  }
}
export default App;